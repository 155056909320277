<template>
  <ChatView />
</template>
<script>
import ChatView from './components/ChatView.vue'

export default {
  name: 'App',
  components: {
    ChatView,
  },
}
</script>
<style>
@font-face {
  font-family: 'regularFont';
  src: url('./assets/fonts/neris-light.otf') format('opentype');
  font-weight: normal;
}

@font-face {
  font-family: 'boldFont';
  src: url('./assets/fonts/neris-black.otf') format('opentype');
  font-weight: bold;
}

html {
  overflow: hidden !important;
  background-color: #181a1a;
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  width: 100%;
  height: 100%;
}

#app {
  font-family: regularFont;
  background: transparent !important;
  font-size: 14px;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: underline;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 80px;
  color: #fff;
}
</style>
