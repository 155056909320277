<template>
  <div ref="talkjs" style="width: 100%; height: 100%">
    <i class="loading">{{ str['processing'] }}</i>
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue'
import Talk from 'talkjs'
import Api from '@/services/Api'

export default {
  setup() {
    const talkjs = ref(null)
    const str = window.appCache?.strings || {}
    const user = window.appCache?.user || null
    const dev = window.appCache?.dev || false
    let chatbox = null

    const sendTotalUnreadMessagesToApp = (value) => {
      try {
        if (window.ReactNativeWebView) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({
              event: 'unread_message_count',
              data: value,
            }),
          )
        }
      } catch (error) {
        console.log('Error posting message to ReactNativeWebView:', error)
      }
    }

    const handleMessage = (event) => {
      try {
        if (event && event.data && !event.data.includes('__talkjs')) {
          const data = JSON.parse(event.data)
          if (data?.event === 'app-enable-chat') {
            if (chatbox) chatbox.onWindowVisibleChanged(true)
          }
          if (data?.event === 'app-disable-chat') {
            if (chatbox) chatbox.onWindowVisibleChanged(false)
          }
        }
      } catch (error) {
        console.log('Error processing message:', error)
      }
    }

    onMounted(async () => {
      try {
        if (!user) {
          return alert('User not found')
        }

        await Talk.ready

        const ptIncludeEmployeeInChat = {
          fm: true,
        }
        const ptTag = Object.keys(PTS_IDS)
          .find((k) => PTS_IDS[k] === user.pt_id)
          ?.toLowerCase()
        const userId = (dev ? 'dev' : 'prod') + '_' + ptTag + '_' + user.id

        const response = await Api.getToken({ id: userId })

        if (response?.success) {
          const clientUser = new Talk.User({
            id: userId,
            name: user.id + ' - ' + user.name,
            locale: 'pt-BR',
            role: ptTag + '_client',
          })
          const ptUser = new Talk.User((dev ? 'dev' : 'prod') + '_' + ptTag + '_' + user.pt_id)
          const session = new Talk.Session({
            appId: response.data.app_id,
            me: clientUser,
            locale: 'pt-BR',
            tokenFetcher: async () => {
              try {
                const tokenResponse = await Api.getToken({ id: userId })
                if (tokenResponse?.success) {
                  return tokenResponse.data.token
                } else {
                  window.location.reload()
                  return null
                }
              } catch {
                window.location.reload()
                return null
              }
            },
          })

          session.unreads.onChange(async (conversations) => {
            let totalUnreadMessages = 0
            conversations.forEach((conversation) => {
              totalUnreadMessages += conversation.unreadMessageCount
            })
            sendTotalUnreadMessagesToApp(totalUnreadMessages)
          })

          const conversation = session.getOrCreateConversation(Talk.oneOnOneId(ptUser, clientUser))
          const custom = {
            customer_token: user.chat_token || '',
          }
          if (user.employee1) {
            custom['employee_' + user.employee1] = 'true'
            if (ptIncludeEmployeeInChat[ptTag]) {
              try {
                const employee1User = await Api.getUser(user.employee1)
                if (employee1User?.data?.[0]) {
                  const employee1TalkUser = new Talk.User({
                    id: (dev ? 'dev' : 'prod') + '_' + ptTag + '_' + user.employee1,
                    name: employee1User.data[0].name,
                    welcomeMessage: null,
                    locale: 'pt-BR',
                    role: ptTag + '_admin',
                  })
                  conversation.setParticipant(employee1TalkUser)
                }
              } catch (error) {
                console.log('Failed to get employee1:', error)
              }
            }
          }
          if (user.employee2) {
            custom['employee_' + user.employee2] = 'true'
            if (ptIncludeEmployeeInChat[ptTag]) {
              try {
                const employee2User = await Api.getUser(user.employee2)
                if (employee2User?.data?.[0]) {
                  const employee2TalkUser = new Talk.User({
                    id: (dev ? 'dev' : 'prod') + '_' + ptTag + '_' + user.employee2,
                    name: employee2User.data[0].name,
                    welcomeMessage: null,
                    locale: 'pt-BR',
                    role: ptTag + '_admin',
                  })
                  conversation.setParticipant(employee2TalkUser)
                }
              } catch (error) {
                console.log('Failed to get employee2:', error)
              }
            }
          }
          conversation.setAttributes({
            custom: custom,
            subject: user.id + ' - ' + user.name,
          })
          conversation.setParticipant(ptUser)
          conversation.setParticipant(clientUser)

          chatbox = session.createChatbox({
            locale: 'pt-BR',
          })
          chatbox.onWindowVisibleChanged(false)
          chatbox.select(conversation)
          chatbox.mount(talkjs.value)

          window.addEventListener('message', handleMessage)
          document.addEventListener('message', handleMessage)
        } else {
          alert(response.message || 'Failed to get token')
        }
      } catch (error) {
        console.log('Failed to initialize TalkJS:', error)
      }
    })

    onUnmounted(() => {
      window.removeEventListener('message', handleMessage)
      document.removeEventListener('message', handleMessage)
    })

    return {
      str,
      user,
      talkjs,
    }
  },
}
</script>
