/* global PTS_TAGS */
import axios from 'axios'

axios.defaults.timeout = 600000
const urls = JSON.parse(process.env.VUE_APP_URL)
const user = window.appCache?.user || null
const dev = window.appCache?.dev || false
const ptTag = user
  ? PTS_TAGS.find(function (tag) {
      return tag.id === user.pt_id
    })
  : null
axios.defaults.url = ptTag ? urls[dev ? 'dev' : 'prod'][ptTag.api].base : ''
axios.defaults.authorization = window.appCache?.token || ''

export default {
  async getToken(data) {
    const response = await axios.get(axios.defaults.url + 'talkjs/user/token?user_id=' + data.id, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.authorization,
      },
    })
    return response.data
  },
  async getUser(id) {
    const response = await axios.get(axios.defaults.url + 'user?filter={"pt_id":' + user.pt_id + ',"id":' + id + '}&fields=["name"]', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: axios.defaults.authorization,
      },
    })
    return response.data
  },
}
